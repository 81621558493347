import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Terms, SEO } from '@/components'

export const pageQuery = graphql`
  query TermsIndex {
    prismicMeta {
      data {
        social_image {
          url
        }
      }
    }
  }
`

const TermsPage = ({ data, pageContext }) => {
  const { social_image } = data.prismicMeta.data

  return (
    <Layout locale={pageContext.lang}>
      <SEO title="Terms of Use" image={social_image.url} />
      <Terms />
    </Layout>
  )
}

export default TermsPage
